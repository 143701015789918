import { DateTime } from 'luxon';

export function dateAsYMD(date: Date = new Date()) {
  return isNaN(date.getTime()) ? new Date().toISOString().split('T')[0] : date.toISOString().split('T')[0];
}

export function dateStringToYMD(date: string) {
  return dateAsYMD(new Date(date));
}

export function dateAsYM(date: Date = new Date()) {
  return isNaN(date.getTime()) ? new Date().toISOString().slice(0, 7) : date.toISOString().slice(0, 7);
}

export function timeForInput(date: string | Date | DateTime) {
  if(date) {
    if(date instanceof DateTime) {
      return date.toISOTime({
        includeOffset: false,
        suppressMilliseconds: true,
        suppressSeconds: true,
      });
    }

    const localDate = new Date(date);
    const minutes = `${localDate.getMinutes()}`.padStart(2, '0');
    const hours = `${localDate.getHours()}`.padStart(2, '0');

    return `${hours}:${minutes}`;
  }

  return undefined;
}

export function dateForInput(date: string | Date | DateTime) {
  if(date) {
    if(date instanceof DateTime) {
      return date.toISOTime({
        includeOffset: false,
        suppressMilliseconds: true,
        suppressSeconds: true,
      });
    }

    const localDate = new Date(date);
    const year = localDate.getFullYear();
    const month = `${localDate.getMonth() + 1}`.padStart(2, '0');
    const day = `${localDate.getDate()}`.padStart(2, '0');
    const minutes = `${localDate.getMinutes()}`.padStart(2, '0');
    const hours = `${localDate.getHours()}`.padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  return undefined;
}

export function dateForDateInput(date: string | Date) {
  if(date) {
    const localDate = new Date(date);
    const year = localDate.getFullYear();
    const month = `${localDate.getMonth() + 1}`.padStart(2, '0');
    const day = `${localDate.getDate()}`.padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  return undefined;
}

export function hoursToDate(date: string) {
  if(date) {
    const localDate = new Date();
    const hourSplit = date.split(':');

    localDate.setHours(parseInt(hourSplit[0]));
    localDate.setMinutes(parseInt(hourSplit[1]));

    return localDate;
  }

  return undefined;
}

export const australianCurrencyFormatter = new Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD',
  // These options are needed to round to whole numbers if that's what you want.
  // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
});

export const australianCurrency = (input: number | bigint) => {
  return australianCurrencyFormatter.format(input);
};

export const timezoneOffset = (new Date()).getTimezoneOffset();

export function getAllDaysInMonth(month: number, year: number) {
  return Array.from(
    { length: new Date(year, month, 0, 0).getDate() },
    (_, i) =>
      new Date(
        new Date(year, month - 1, i + 1).getTime() - (timezoneOffset * 60 * 1000),
      )
        .toISOString()
        .split('T')[0],
  ).reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / 7);

    if(!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);
}

export function isSaturday(day: Date) {
  return day.getDay() === 6;
}

export function isSunday(day: Date) {
  return day.getDay() === 0;
}

export function safeDenominator(denominator: number) {
  return denominator === 0 ? 1 : denominator;
}

export const numberFormatter = new Intl.NumberFormat('en-AU', {
  // These options are needed to round to whole numbers if that's what you want.
  // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
});

export const australianNumber = (input: number | bigint) => {
  return numberFormatter.format(input);
};

export const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const env = () => {
  return import.meta.env.VITE_APP_ENV || 'production';
};

export function enumKeyFromValue<T>(enumForValue: T, value: ObjectValues<T>): keyof T & string {
  return Object.keys(enumForValue).find((key) => enumForValue[key] === value).toLowerCase() as keyof T & string;
}

export function titleCase(str: string) {
  return str.replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
    .replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase());
}

export function parseUrlParams(url: string | undefined) {
  if(!url) {
    return undefined;
  }

  const params = new URL(url);

  return Object.fromEntries(params.searchParams.entries());
}

export function falsey(value: unknown) {
  return !value && value !== 0;
}

export const inCapacitor = !!window.Capacitor;

export function isNumber(value: number) {
  return !Number.isNaN(value) && Number.isFinite(value);
}

export function roundDecimals(value: number, precision = 1e2) {
  return Math.round(value * precision) / precision;
}

export function cumulativeSum(values: number[]) {
  const sumAccumulator = ((sum) => (value: number) => sum += value)(0);

  return values.map(sumAccumulator);
}

export function promiseWithResolver() {
  let res: (value?: unknown) => void;

  const prom = new Promise((innerResolver) => {
    res = innerResolver;
  });

  return {
    promise: prom,
    resolvePromise: res,
  };
}
