import { Model } from 'pinia-orm';
import { BelongsTo, HasMany, Num, Str } from 'pinia-orm/dist/decorators';
import Plant from './Plant';
import Project from './Project';
/* --- user header --- */
/* --- end user header --- */

export default class PlantCategory extends Model {
  static entity = 'App\\Models\\PlantCategory';
  // fields
  @Num(0)
  declare id: number;
  @Num(0)
  declare project_id: number;
  @Str('')
  declare name: string;
  @Str('')
  declare deleted_at: string | undefined;
  @Str('')
  declare created_at: string | undefined;
  @Str('')
  declare updated_at: string | undefined;
  // relations
  @BelongsTo(() => Project, 'project_id', 'id')
  declare project: Project;
  @HasMany(() => Plant, 'plant_category_id', 'id')
  declare plants: Plant[];
  /* --- user code --- */
  /* --- end user code --- */
}
