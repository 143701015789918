<template>
  <Teleport to="#modals">
    <div
      class="absolute"
      tabindex="0"
    >
      <div class="fixed bottom-0 left-0 z-50 flex justify-center items-center sm:m-4 inset-0">
        <div class="m-4">
          <div
            ref="content"
            class="flex flex-col shadow-xl bg-white sm:rounded md:max-h-[90vh] h-screen sm:h-full md:h-auto"
            @mousedown.stop=""
          >
            <div class="max-h-content relative rounded-t-lg border-b border-gray-300">
              <button
                class="group absolute z-50 right-1 top-1"
                type="button"
                @click.prevent="$emit('close')"
              >
                <Icon
                  name="close"
                  class="h-6 w-6 hover:text-red-600 m-1"
                />
              </button>

              <slot name="header">
                Header
              </slot>
            </div>

            <div class="h-full bg-white">
              <slot name="body">
                Body
              </slot>
            </div>

            <div class="min-h-3 border-t-2 border-gray-300 bg-white sm:rounded-b sm:border-t">
              <slot name="footer">
                Footer
              </slot>
            </div>
          </div>
        </div>
      </div>

      <div
        class="opacity-25 fixed inset-0 z-40 bg-black"
        @click.prevent="$emit('close')"
      />
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { onKeyStroke } from '@vueuse/core';

withDefaults(
  defineProps<{
    classes?: string;
  }>(),
  {
    classes: '',
  },
);

const emits = defineEmits(['close']);

onKeyStroke('Escape', () => {
  emits('close');
});

// const content = ref();

// onClickOutside(content, () => {
//   emits('close');
// });
</script>
