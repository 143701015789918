import Claim from '@/models/Claim';
import ClaimsService from '@/services/ClaimsService';
import { defineStore } from 'pinia';
import { useRepo } from 'pinia-orm';

export const useClaimsStore = defineStore('claims', {
  state: () => ({}),

  getters: {
    models: () => useRepo(Claim),
  },

  actions: {
    async fetchClaims(projectId: Id) {
      const [data, error] = await ClaimsService.index(projectId);

      if(data) {
        this.models.save(data.data);

        return data;
      }

      throw error;
    },

    async createClaim(projectId: Id, request: ApiRequests['api.v1.claims.store']) {
      const [data, error] = await ClaimsService.create(projectId, request);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async updateClaim(id: Id, request: ApiRequests['api.v1.claims.update']) {
      const [data, error] = await ClaimsService.update(id, request);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async deleteClaim(id: Id) {
      const [data, error] = await ClaimsService.delete(id);

      if(data) {
        this.models.destroy(id);

        return data;
      }

      throw error;
    },

    async fetchClaim(id: Id) {
      const [data, error] = await ClaimsService.show(id);

      if(data) {
        this.models.save(data.data);

        return data;
      }

      throw error;
    },

    async sendClaim(id: Id, request: ApiRequests['api.v1.claims.send.draft']) {
      const [data, error] = await ClaimsService.send(id, request);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async sendContractor(id: Id, signedParams: unknown, request: ApiRequests['api.v1.claims.send.contractor']) {
      const [data, error] = await ClaimsService.sendContractor(id, signedParams, request);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async sendPqs(id: Id, signedParams: unknown, request: ApiRequests['api.v1.claims.send.pqs']) {
      const [data, error] = await ClaimsService.sendPqs(id, signedParams, request);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async submit(id: Id, signedParams: unknown, request: ApiRequests['api.v1.claims.certify']) {
      const [data, error] = await ClaimsService.submit(id, signedParams, request);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async export(id: Id) {
      const [data, error] = await ClaimsService.export(id);

      if(data) {
        return data;
      }

      throw error;
    },

    async certifyLite(id: Id, request: ApiRequests['api.v1.claims.lite.certify']) {
      const [data, error] = await ClaimsService.certifyLite(id, request);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async fetchAdminIndex() {
      const [data, error] = await ClaimsService.admin.index();

      if(data) {
        this.models.save(data.claims.data);

        return data;
      }

      throw error;
    },

    async fetchAdminClaim(id: Id) {
      const [data, error] = await ClaimsService.admin.show(id);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async deleteAdminClaim(id: Id) {
      const [data, error] = await ClaimsService.admin.delete(id);

      if(data) {
        this.models.destroy(id);

        return data;
      }

      throw error;
    },
  },
});
