import { route } from '@/ziggy-shim';
import { api, apiConfig } from './LaravelClient';

export default {
  async index(projectId: Id) {
    return await api(route('api.v1.claims.index', projectId), 'get');
  },

  async create(projectId: Id, data: ApiRequests['api.v1.claims.store']) {
    return await api(route('api.v1.claims.store', projectId), 'post', {
      data,
    });
  },

  async update(id: Id, data: ApiRequests['api.v1.claims.update']) {
    return await api(route('api.v1.claims.update', id), 'patch', {
      data,
    });
  },

  async delete(id: Id) {
    return await api(route('api.v1.claims.destroy', id), 'delete');
  },

  async show(id: Id) {
    return await api(route('api.v1.claims.show', id), 'get');
  },

  async send(id: Id, data: ApiRequests['api.v1.claims.send.draft']) {
    return await api(route('api.v1.claims.send.draft', id), 'post', {
      data,
    });
  },

  async export(id: Id) {
    return await api(route('api.v1.claims.lite.export', id), 'post');
  },

  async sendContractor(id: Id, signedParams: unknown, data: ApiRequests['api.v1.claims.send.contractor']) {
    return await apiConfig(route('api.v1.claims.send.contractor', id), 'post', {
      params: signedParams,
      paramsSerializer: {
        serialize: (params) => {
          return new URLSearchParams(params).toString();
        },
      },
    }, {
      data,
    });
  },

  async sendPqs(id: Id, signedParams: unknown, data: ApiRequests['api.v1.claims.send.pqs']) {
    return await apiConfig(route('api.v1.claims.send.pqs', id), 'post', {
      params: signedParams,
      paramsSerializer: {
        serialize: (params) => {
          return new URLSearchParams(params).toString();
        },
      },
    }, {
      data,
    });
  },

  async submit(id: Id, signedParams: unknown, data: ApiRequests['api.v1.claims.certify']) {
    return await apiConfig(route('api.v1.claims.certify', id), 'post', {
      params: signedParams,
      paramsSerializer: {
        serialize: (params) => {
          return new URLSearchParams(params).toString();
        },
      },
    }, {
      data,
    });
  },

  async certifyLite(id: Id, data: ApiRequests['api.v1.claims.lite.certify']) {
    return await api(route('api.v1.claims.lite.certify', id), 'post', {
      data,
    });
  },

  admin: {
    async index() {
      return await api(route('api.v1.admin.claims.index'), 'get');
    },

    async show(id: Id) {
      return await api(route('api.v1.admin.claims.show', id), 'get');
    },

    async delete(id: Id) {
      return await api(route('api.v1.admin.claims.destroy', id), 'delete');
    },
  },
};
