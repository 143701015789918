import { Model } from 'pinia-orm';
import { BelongsTo, MorphMany, Num, Str } from 'pinia-orm/dist/decorators';
import Claim from './Claim';
import Comment from './Comment';
import TradeItem from './TradeItem';
/* --- user header --- */
/* --- end user header --- */

export default class TradeItemCost extends Model {
  static entity = 'App\\Models\\TradeItemCost';
  // fields
  @Num(0)
  declare id: number;
  @Num(0)
  declare claim_id: number;
  @Num(0)
  declare trade_item_id: number;
  @Num(0)
  declare current_claim: number | undefined;
  @Num(0)
  declare current_certified: number | undefined;
  @Str('')
  declare created_at: string | undefined;
  @Str('')
  declare updated_at: string | undefined;
  // relations
  @BelongsTo(() => Claim, 'claim_id', 'id')
  declare claim: Claim;
  @BelongsTo(() => TradeItem, 'trade_item_id', 'id')
  declare trade_item: TradeItem;
  @MorphMany(() => Comment, 'commentable_id', 'commentable_type', 'id')
  declare comments: Comment[];
  /* --- user code --- */

  /* --- end user code --- */
}
