import { route } from '@/ziggy-shim';
import { api, CastValue } from './LaravelClient';

export default {
  async create(projectId: Id, data: ApiRequests['api.v1.labours.store']) {
    return await api(route('api.v1.labours.store', projectId), 'post', {
      data,
      casts: {
        supplier_name: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
        supplier_id: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
      },
    });
  },

  async update(id: Id, data: ApiRequests['api.v1.labours.update']) {
    return await api(route('api.v1.labours.update', id), 'patch', {
      data,
    });
  },

  async delete(id: Id) {
    return await api(route('api.v1.labours.destroy', id), 'delete');
  },

  async show(id: Id) {
    return await api(route('api.v1.labours.show', id), 'get');
  },

  async index(projectId: Id, data: ApiRequests['api.v1.labours.index']) {
    return await api(route('api.v1.labours.index', projectId), 'get', {
      data,
      casts: {
        day_max: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
        day_min: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
        first_name: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
        last_name: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
        supplier_name: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
        vendor_ref: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
      },
    });
  },

  async daily(projectId: Id, data: ApiRequests['api.v1.labours.daily']) {
    return await api(route('api.v1.labours.daily', projectId), 'get', {
      data,
      casts: {
        day: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
      },
    });
  },
};
