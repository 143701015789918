import r from 'ziggy-js';
import { Ziggy } from './ziggy';

type UnionToIntersection<U> = (
  U extends never ? never : (arg: U) => never
) extends (arg: infer I) => void ? I :
  never;

type UnionToTuple<T> = UnionToIntersection<
  T extends never ? never : (t: T) => T
> extends (_: never) => infer W ? [...UnionToTuple<Exclude<T, W>>, number | string] :
  [];

type Length<Type extends readonly unknown[]> = Type['length'] extends 1 ? string | number | [number | string] : Type;
type Route = Record<string, string>;
type HasBindings = {
  bindings: Route;
};

export function route<T extends keyof typeof Ziggy['routes']>(
  name: T,
  ...params: typeof Ziggy['routes'][T] extends HasBindings ? typeof Ziggy['routes'][T]['bindings'] extends Route ? [
        | Record<keyof typeof Ziggy['routes'][T]['bindings'], string | number>
        | Length<UnionToTuple<keyof typeof Ziggy['routes'][T]['bindings']>>,
      ] :
    [] :
    []
): T {
  if(params.length > 0) {
    return r(name, params[0], false, Ziggy);
  }

  return r(name, undefined, false, Ziggy);
}
