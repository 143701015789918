import { isSaturday, isSunday } from '@/helpers';
import { Model, useRepo } from 'pinia-orm';
import { Attr, BelongsTo, Bool, MorphMany, Num, Str } from 'pinia-orm/dist/decorators';
import Comment from './Comment';
import Labour from './Labour';
import ProjectSupplier from './ProjectSupplier';
import User from './User';
import VariationOrder from './VariationOrder';
import WbsCode from './WbsCode';
/* --- user header --- */
import { DateTime } from 'luxon';
/* --- end user header --- */

export default class LabourDailyRecord extends Model {
  static entity = 'App\\Models\\LabourDailyRecord';
  // fields
  @Num(0)
  declare id: number;
  @Num(0)
  declare labour_id: number;
  @Num(0)
  declare user_id: number | undefined;
  @Num(0)
  declare wbs_code_id: number;
  @Num(0)
  declare variation_order_id: number | undefined;
  @Str('')
  declare approved_at: string | undefined;
  @Str('')
  declare day: string;
  @Str('')
  declare start_at: string;
  @Str('')
  declare end_at: string;
  @Str('')
  declare time_zone: string;
  @Num(0)
  declare accrual_rate: number;
  @Num(0)
  declare paid: number | undefined;
  @Str('')
  declare vendor_ref: string | undefined;
  @Bool(false)
  declare is_public_holiday: boolean;
  @Bool(false)
  declare minor_break: boolean;
  @Bool(false)
  declare major_break: boolean;
  @Str('')
  declare area: string;
  @Str('')
  declare element: string;
  @Str('')
  declare activity: string;
  @Str('')
  declare created_at: string | undefined;
  @Str('')
  declare updated_at: string | undefined;
  // relations
  @BelongsTo(() => Labour, 'labour_id', 'id')
  declare labour: Labour;
  @BelongsTo(() => User, 'user_id', 'id')
  declare user: User;
  @BelongsTo(() => WbsCode, 'wbs_code_id', 'id')
  declare wbsCode: WbsCode;
  @BelongsTo(() => VariationOrder, 'variation_order_id', 'id')
  declare variationOrder: VariationOrder;
  @MorphMany(() => Comment, 'commentable_id', 'commentable_type', 'id')
  declare comments: Comment[];
  /* --- user code --- */
  // Computed Properties
  @Attr(undefined)
  declare $cost: number | undefined;

  get hours() {
    const end = new Date(this.end_at);
    const start = new Date(this.start_at);
    let diff = Math.abs(end.getTime() - start.getTime()) / 3600000;

    if(this.minor_break) {
      diff -= 0.25;
    }

    if(this.major_break) {
      diff -= 0.5;
    }

    return diff;
  }

  get hoursWithoutBreaks() {
    const end = new Date(this.end_at);
    const start = new Date(this.start_at);
    const diff = Math.abs(end.getTime() - start.getTime()) / 3600000;

    return diff;
  }

  get cost() {
    if(this.$cost !== undefined) {
      return this.$cost;
    }

    const projectSupplier = useRepo(ProjectSupplier)
      .where('supplier_id', this.labour.supplier_id)
      .where('project_id', this.labour.project_id)
      .first();

    if(projectSupplier) {
      const recordDate = new Date(this.day);

      if(this.is_public_holiday) {
        return this.hours * this.accrual_rate * projectSupplier.public_holiday_multiplier;
      } else if(isSaturday(recordDate)) {
        return this.hours * this.accrual_rate * projectSupplier.saturday_multiplier;
      } else if(isSunday(recordDate)) {
        return this.hours * this.accrual_rate * projectSupplier.sunday_multiplier;
      }

      const records = useRepo(LabourDailyRecord)
        .where('labour_id', this.labour_id)
        .where('day', this.day)
        .where('start_at', (start_at) => {
          return start_at <= this.start_at;
        })
        .orderBy('start_at')
        .get();

      const baseRate = this.accrual_rate;
      let totalHours = 0;
      let overtimePay = 0;

      const overtimeThresholds = projectSupplier.overtime.sort((a, b) => {
        return a.threshold - b.threshold;
      });

      for(let i = 0;i < records.length;i++) {
        const record = records[i];
        let remainderHours = record.hours;
        const otherRecordHours = totalHours;

        totalHours += record.hours;
        overtimePay = 0;
        let prevThreshold = 0;
        let prevMultiplier = 1;
        let addedHours = 0;

        for(let j = 0;j < overtimeThresholds.length;j++) {
          const { threshold, multiplier } = overtimeThresholds[j];
          const intervalHours = Math.min(totalHours - prevThreshold, threshold - prevThreshold);

          addedHours += intervalHours;

          if(addedHours > otherRecordHours) {
            // hours for the interval can either be bleed over from previous threshold or some amount in current threshold
            // if it's a bleed over then the addedHours - otherRecordHours will represent this bleed over.
            const hoursToAdd = Math.min(intervalHours, addedHours - otherRecordHours);
            const intervalOvertimePay = hoursToAdd * baseRate * prevMultiplier;

            overtimePay += intervalOvertimePay;

            remainderHours -= hoursToAdd;
          }

          prevThreshold = threshold;
          prevMultiplier = multiplier;

          if(totalHours <= threshold) {
            break;
          }
        }

        if(remainderHours > 0) {
          overtimePay += remainderHours * baseRate * prevMultiplier;
        }
      }

      return overtimePay;
    }

    return this.hours * this.accrual_rate;
  }

  get accrual() {
    return Math.max(0, this.cost - this.paid);
  }

  get approvedAt() {
    return this.approved_at ? new Date(this.approved_at) : undefined;
  }

  get startAt() {
    return this.start_at ? DateTime.fromISO(this.start_at).setZone(this.time_zone) : undefined;
  }

  get endAt() {
    return this.end_at ? DateTime.fromISO(this.end_at).setZone(this.time_zone) : undefined;
  }

  get isDay() {
    const hours = new Date(this.start_at).getHours();

    return hours > 6 &&
      hours < 17;
  }
  /* --- end user code --- */
}
