import { Preferences } from '@capacitor/preferences';

export const StoreKeys = {
  AUTH_TOKEN: 'auth-token',
};

export default {
  async store(key: string, value: unknown) {
    if(!window.Capacitor) {
      throw new Error('Not running in Capacitor');
    }

    await Preferences.set({
      key,
      value: JSON.stringify(value),
    });
  },

  async get(key: string): Promise<unknown> {
    if(!window.Capacitor) {
      throw new Error('Not running in Capacitor');
    }

    const { value } = await Preferences.get({ key });

    if(value) {
      return JSON.parse(value);
    }

    return undefined;
  },

  async delete(key: string): Promise<void> {
    if(!window.Capacitor) {
      throw new Error('Not running in Capacitor');
    }

    return await Preferences.remove({ key });
  },
};
