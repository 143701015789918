import { Model } from 'pinia-orm';
import { BelongsTo, HasMany, Num, Str } from 'pinia-orm/dist/decorators';
import DailyQuantityLoads from './DailyQuantityLoads';
import Project from './Project';
/* --- user header --- */
/* --- end user header --- */

export default class AssetType extends Model {
  static entity = 'App\\Models\\AssetType';
  // fields
  @Num(0)
  declare id: number;
  @Num(0)
  declare project_id: number;
  @Str('')
  declare name: string;
  @Num(0)
  declare cubes_per_load: number;
  @Num(0)
  declare tonnes_per_load: number;
  @Str('')
  declare deleted_at: string | undefined;
  @Str('')
  declare created_at: string | undefined;
  @Str('')
  declare updated_at: string | undefined;
  // relations
  @BelongsTo(() => Project, 'project_id', 'id')
  declare project: Project;
  @HasMany(() => DailyQuantityLoads, 'asset_type_id', 'id')
  declare quantity_loads: DailyQuantityLoads[];
  /* --- user code --- */
  /* --- end user code --- */
}
