import { Model, useRepo } from 'pinia-orm';
import { Attr, BelongsTo, Bool, MorphMany, Num, Str } from 'pinia-orm/dist/decorators';
import Comment from './Comment';
import Plant from './Plant';
import User from './User';
import VariationOrder from './VariationOrder';
import WbsCode from './WbsCode';
/* --- user header --- */
import { DateTime } from 'luxon';
/* --- end user header --- */

export default class PlantDailyRecord extends Model {
  static entity = 'App\\Models\\PlantDailyRecord';
  // fields
  @Num(0)
  declare id: number;
  @Num(0)
  declare plant_id: number;
  @Num(0)
  declare user_id: number | undefined;
  @Num(0)
  declare wbs_code_id: number | undefined;
  @Num(0)
  declare variation_order_id: number | undefined;
  @Str('')
  declare approved_at: string | undefined;
  @Str('')
  declare day: string;
  @Str('')
  declare start_at: string | undefined;
  @Str('')
  declare end_at: string | undefined;
  @Str('')
  declare time_zone: string;
  @Num(0)
  declare smu_start: number | undefined;
  @Num(0)
  declare smu_end: number | undefined;
  @Num(0)
  declare breakdown: number | undefined;
  @Num(0)
  declare standdown: number | undefined;
  @Num(0)
  declare accrual_rate: number | undefined;
  @Num(0)
  declare paid: number | undefined;
  @Str('')
  declare vendor_ref: string | undefined;
  @Str('')
  declare area: string | undefined;
  @Str('')
  declare element: string | undefined;
  @Str('')
  declare activity: string | undefined;
  @Bool(false)
  declare off_hire: boolean;
  @Str('')
  declare created_at: string | undefined;
  @Str('')
  declare updated_at: string | undefined;
  // relations
  @BelongsTo(() => Plant, 'plant_id', 'id')
  declare plant: Plant;
  @BelongsTo(() => User, 'user_id', 'id')
  declare user: User;
  @BelongsTo(() => WbsCode, 'wbs_code_id', 'id')
  declare wbsCode: WbsCode;
  @BelongsTo(() => VariationOrder, 'variation_order_id', 'id')
  declare variationOrder: VariationOrder;
  @MorphMany(() => Comment, 'commentable_id', 'commentable_type', 'id')
  declare comments: Comment[];
  /* --- user code --- */
  // Computed Properties
  @Attr(undefined)
  declare $cost: number | undefined;

  get hours() {
    const end = new Date(this.end_at);
    const start = new Date(this.start_at);
    let diff = Math.abs(end.valueOf() - start.valueOf()) / 3600000;

    if(this.smu_start && this.smu_end) {
      diff = this.smu_end - this.smu_start;
    }

    diff -= this.breakdown;

    diff -= this.standdown;

    return diff;
  }

  get approvedAt() {
    return this.approved_at ? new Date(this.approved_at) : undefined;
  }

  get cost() {
    if(this.$cost) {
      return this.$cost;
    }

    if(this.plant.wet) {
      return Math.max(0, (this.hours + (this.plant.pay_standby ? this.standdown : 0)) * this.accrual_rate);
    }

    const dailyRecords = useRepo(PlantDailyRecord).where('plant_id', this.plant_id).where('day', this.day).get();
    let dailyTotal = 0;

    dailyRecords.forEach((record) => {
      dailyTotal += record.hours + (this.plant.pay_standby ? record.standdown : 0);
    });

    if(dailyTotal === 0) {
      return 0;
    }

    const percentage = (this.hours + (this.plant.pay_standby ? this.standdown : 0)) / dailyTotal;

    return Math.max(0, this.accrual_rate * percentage);
  }

  get accrual() {
    return Math.max(0, this.cost - this.paid);
  }

  get startAt() {
    return this.start_at ? DateTime.fromISO(this.start_at).setZone(this.time_zone) : undefined;
  }

  get endAt() {
    return this.end_at ? DateTime.fromISO(this.end_at).setZone(this.time_zone) : undefined;
  }

  get isDay() {
    if(this.start_at) {
      const hours = new Date(this.start_at).getHours();

      return hours > 6 &&
        hours < 17;
    }

    return true;
  }
  /* --- end user code --- */
}
