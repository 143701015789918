import { useFormData } from '@/composables/useFormData';
import Plant from '@/models/Plant';
import PlantHirePeriod from '@/models/PlantHirePeriod';
import PlantService from '@/services/PlantService';
import { defineStore } from 'pinia';
import { useRepo } from 'pinia-orm';
import { usePluck } from 'pinia-orm/dist/helpers';

export const usePlantsStore = defineStore('plants', {
  state: () => ({
    filter: useFormData({
      day_max: undefined,
      day_min: undefined,
      fleet: undefined,
      supplier_name: undefined,
      wbs_code_id: undefined,
      wet: undefined,
      vendor_ref: undefined,
    }),

    active: false,
  }),

  getters: {
    models: () => useRepo(Plant),
  },

  actions: {
    resetFilter() {
      this.active = false;
      this.filter.resetData();
    },

    async fetchPlants(projectId: Id, form: ApiRequests['api.v1.plants.index'] = {}) {
      const [data, error] = await PlantService.index(projectId, form);

      if(data) {
        this.models.save(data.data);

        return data;
      }

      throw error;
    },

    async fetchPlant(id: Id) {
      const [data, error] = await PlantService.show(id);

      if(data) {
        return this.models.save(data);
      }

      throw error;
    },

    async fetchDailyPlants(projectId: Id, day: ApiRequests['api.v1.plants.daily']) {
      const [data, error] = await PlantService.daily(projectId, day);

      if(data) {
        return this.models.save(data);
      }

      throw error;
    },

    async savePlant(projectId: Id, plant: ApiRequests['api.v1.plants.store']) {
      const [data, error] = await PlantService.create(projectId, plant);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async updatePlant(plantId: Id, form: ApiRequests['api.v1.plants.update']) {
      const [data, error] = await PlantService.update(plantId, form);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async deletePlant(id: Id) {
      const [data, error] = await PlantService.delete(id);

      if(data) {
        this.models.destroy(data.id);

        return data;
      }

      throw error;
    },

    async hire(plantId: Id, form: ApiRequests['api.v1.plants.hire']) {
      const [data, error] = await PlantService.hire(plantId, form);

      if(data) {
        const hirePeriods = useRepo(PlantHirePeriod);
        const periodIds = usePluck(hirePeriods.where('plant_id', data.id).get(), 'id');

        hirePeriods.destroy(periodIds);

        this.models.save(data);

        return data;
      }

      throw error;
    },
  },
});
