import { Model } from 'pinia-orm';
import { BelongsTo, Num, Str } from 'pinia-orm/dist/decorators';
import AssetType from './AssetType';
import DailyQuantity from './DailyQuantity';
/* --- user header --- */
/* --- end user header --- */

export default class DailyQuantityLoads extends Model {
  static entity = 'App\\Models\\DailyQuantityLoads';
  // fields
  @Num(0)
  declare id: number;
  @Str('')
  declare load_unit: string | undefined;
  @Num(0)
  declare loads: number | undefined;
  @Num(0)
  declare asset_type_id: number | undefined;
  @Num(0)
  declare daily_quantity_id: number | undefined;
  @Str('')
  declare created_at: string | undefined;
  @Str('')
  declare updated_at: string | undefined;
  // relations
  @BelongsTo(() => AssetType, 'asset_type_id', 'id')
  declare asset_type: AssetType;
  @BelongsTo(() => DailyQuantity, 'daily_quantity_id', 'id')
  declare daily_quantity: DailyQuantity;
  /* --- user code --- */
  /* --- end user code --- */
}
