import { Model } from 'pinia-orm';
import { BelongsToMany, Bool, MorphMany, Num, Str } from 'pinia-orm/dist/decorators';
import Comment from './Comment';
import Company from './Company';
import Permissions from './Permissions';
import Project from './Project';
import Role from './Role';
/* --- user header --- */
/* --- end user header --- */

export default class User extends Model {
  static entity = 'App\\Models\\User';
  // fields
  @Num(0)
  declare id: number;
  @Str('')
  declare name: string;
  @Str('')
  declare email: string;
  @Bool(false)
  declare is_disabled: boolean;
  @Str('')
  declare created_at: string | undefined;
  @Str('')
  declare updated_at: string | undefined;
  // relations
  @BelongsToMany(() => Role, () => Permissions, 'user_id', 'role_id', 'id', 'id')
  declare roles: Role[];
  @BelongsToMany(() => Project, () => Permissions, 'user_id', 'project_id', 'id', 'id')
  declare projects: Project[];
  @BelongsToMany(() => Company, () => Permissions, 'user_id', 'company_id', 'id', 'id')
  declare companies: Company[];
  @MorphMany(() => Comment, 'creator_id', 'creator_type', 'id')
  declare comments: Comment[];
  /* --- user code --- */
  /* --- end user code --- */
}
