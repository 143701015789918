import { Model } from 'pinia-orm';
import { Attr, BelongsTo, HasMany, Num, Str } from 'pinia-orm/dist/decorators';
import LabourDailyRecord from './LabourDailyRecord';
import Project from './Project';
import Supplier from './Supplier';
/* --- user header --- */
import { dateAsYMD } from '@/helpers';
/* --- end user header --- */

export default class Labour extends Model {
  static entity = 'App\\Models\\Labour';
  // fields
  @Num(0)
  declare id: number;
  @Str('')
  declare first_name: string;
  @Str('')
  declare last_name: string;
  @Str('')
  declare type: string;
  @Num(0)
  declare supplier_id: number;
  @Num(0)
  declare project_id: number;
  @Num(0)
  declare rate: number;
  @Str('')
  declare cw_ref: string;
  @Str('')
  declare created_at: string | undefined;
  @Str('')
  declare updated_at: string | undefined;
  @Str('')
  declare deleted_at: string | undefined;
  // relations
  @BelongsTo(() => Project, 'project_id', 'id')
  declare project: Project;
  @HasMany(() => LabourDailyRecord, 'labour_id', 'id')
  declare records: LabourDailyRecord[];
  @BelongsTo(() => Supplier, 'supplier_id', 'id')
  declare supplier: Supplier;
  /* --- user code --- */

  // Computed Properties
  @Attr(undefined)
  declare $total_hours: number | undefined;
  @Attr(undefined)
  declare $paid_to_date: number | undefined;
  @Attr(undefined)
  declare $total_accrual: number | undefined;

  get totalHours() {
    return this.$total_hours || this.records?.reduce((total, value) => total + value.hours, 0) || 0;
  }

  get paidToDate() {
    return this.$paid_to_date || this.records
      ?.filter((record) => record.paid)
      .reduce((total, value) => total + value.paid, 0) ||
      0;
  }

  get totalAccrual() {
    return this.$total_accrual || this.records
      ?.filter((record) => !record.paid)
      .reduce((total, value) => total + value.accrual_rate * value.hours, 0) ||
      0;
  }

  dailyHours(date: Date) {
    return this.records?.filter((record) => {
      return record.day === dateAsYMD(date);
    }).reduce((total, value) => total + value.hours, 0) || 0;
  }

  dailyHoursWithoutBreaks(date: Date) {
    return this.records?.filter((record) => {
      return record.day === dateAsYMD(date);
    }).reduce((total, value) => total + value.hoursWithoutBreaks, 0) || 0;
  }

  dailyRecords(date: Date) {
    return this.records?.filter((record) => {
      return record.day === dateAsYMD(date);
    });
  }
  /* --- end user code --- */
}
