import { Model } from 'pinia-orm';
import { BelongsTo, Num, Str } from 'pinia-orm/dist/decorators';
import Project from './Project';
/* --- user header --- */
/* --- end user header --- */

export default class WbsCode extends Model {
  static entity = 'App\\Models\\WbsCode';
  // fields
  @Num(0)
  declare id: number;
  @Str('')
  declare code: string;
  @Str('')
  declare description: string;
  @Str('')
  declare unit: string;
  @Num(0)
  declare tender_production_rate: number;
  @Num(0)
  declare tender_quantity: number;
  @Num(0)
  declare to_complete_production_rate: number;
  @Num(0)
  declare final_quantity: number;
  @Num(0)
  declare tender_cost: number;
  @Num(0)
  declare project_id: number;
  // relations
  @BelongsTo(() => Project, 'project_id', 'id')
  declare project: Project;
  /* --- user code --- */

  public get unitCost(): number {
    return this.tender_cost / this.tender_quantity;
  }

  public get days(): number {
    return this.tender_quantity / this.tender_production_rate;
  }

  /* --- end user code --- */
}
