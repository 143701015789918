import { Model } from 'pinia-orm';
import { HasMany, Num, Str } from 'pinia-orm/dist/decorators';
import Project from './Project';
import ProjectSupplier from './ProjectSupplier';
/* --- user header --- */
/* --- end user header --- */

export default class Company extends Model {
  static entity = 'App\\Models\\Company';
  // fields
  @Num(0)
  declare id: number;
  @Str('')
  declare name: string;
  @Str('')
  declare created_at: string | undefined;
  @Str('')
  declare updated_at: string | undefined;
  // relations
  @HasMany(() => Project, 'company_id', 'id')
  declare projects: Project[];
  @HasMany(() => ProjectSupplier, 'company_id', 'id')
  declare suppliers: ProjectSupplier[];
  /* --- user code --- */
  /* --- end user code --- */
}
