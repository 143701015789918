import { inCapacitor } from '@/helpers';
import AxiosClient from './AxiosClient';
import CapacitorService, { StoreKeys } from './CapacitorService';

export default {
  async login({ email, password }: { email: string; password: string }) {
    if(inCapacitor) {
      const [data, error] = await AxiosClient.postRequest('sanctum/login', {
        email,
        password,
        // TODO: 11/23/23 get device name
        device_name: 'test',
      });

      if(data) {
        AxiosClient.axiosClient.defaults.headers.common.Authorization = `Bearer ${data.access_token}`;

        CapacitorService.store(StoreKeys.AUTH_TOKEN, data.access_token);
      }

      return [data, error];
    }

    return await AxiosClient.postRequest('login', {
      email,
      password,
    });
  },

  async attachBearerTokenFromStorage() {
    const sanctumToken = await CapacitorService.get(StoreKeys.AUTH_TOKEN);

    if(sanctumToken) {
      AxiosClient.axiosClient.defaults.headers.common.Authorization = `Bearer ${sanctumToken}`;
    }
  },

  async getLoggedInUser() {
    return await AxiosClient.getRequest('api/v1/user');
  },

  async logout() {
    if(inCapacitor) {
      return await AxiosClient.postRequest('sanctum/logout');
    } else {
      return await AxiosClient.postRequest('logout');
    }
  },

  async resetPassword({
    email,
    password,
    password_confirmation,
    token,
  }: { email: string; password: string; password_confirmation: string; token: string }) {
    return await AxiosClient.postRequest('reset-password', {
      email,
      password,
      password_confirmation,
      token,
    });
  },
};
