import { Model } from 'pinia-orm';
import { BelongsTo, Bool, Num, Str } from 'pinia-orm/dist/decorators';
import Plant from './Plant';
/* --- user header --- */
/* --- end user header --- */

export default class PlantHirePeriod extends Model {
  static entity = 'App\\Models\\PlantHirePeriod';
  // fields
  @Num(0)
  declare id: number;
  @Num(0)
  declare plant_id: number;
  @Str('')
  declare on_hired_at: string | undefined;
  @Str('')
  declare off_hired_at: string | undefined;
  @Str('')
  declare created_at: string | undefined;
  @Str('')
  declare updated_at: string | undefined;
  // mutators
  @Bool(false)
  declare active: boolean;
  // relations
  @BelongsTo(() => Plant, 'plant_id', 'id')
  declare plant: Plant;
  /* --- user code --- */
  get onHiredAt() {
    return this.on_hired_at ? new Date(this.on_hired_at) : undefined;
  }

  get offHiredAt() {
    return this.off_hired_at ? new Date(this.off_hired_at) : undefined;
  }
  /* --- end user code --- */
}
