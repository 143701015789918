import { route } from '@/ziggy-shim';
import { api, apiConfig } from './LaravelClient';

export default {
  async create(data: ApiRequests['api.v1.admin.companies.store']) {
    return await api(route('api.v1.admin.companies.store'), 'post', {
      data,
    });
  },

  async update(id: Id, data: ApiRequests['api.v1.companies.update']) {
    return await api(route('api.v1.companies.update', id), 'patch', {
      data,
    });
  },

  async delete(id: Id) {
    return await api(route('api.v1.admin.companies.destroy', id), 'delete');
  },

  async adminIndex(page = 1) {
    return await apiConfig(route('api.v1.admin.companies.index'), 'get', {
      params: {
        page,
      },
    });
  },

  async adminShow(id: Id) {
    return await api(route('api.v1.admin.companies.show', id), 'get');
  },

  async adminUpdate(id: Id, data: ApiRequests['api.v1.admin.companies.update']) {
    return await api(route('api.v1.admin.companies.update', id), 'patch', {
      data,
    });
  },
};
