import { Model } from 'pinia-orm';
import { HasMany, Num, Str } from 'pinia-orm/dist/decorators';
import Labour from './Labour';
import Plant from './Plant';
import ProjectSupplier from './ProjectSupplier';
/* --- user header --- */
/* --- end user header --- */

export default class Supplier extends Model {
  static entity = 'App\\Models\\Supplier';
  // fields
  @Num(0)
  declare id: number;
  @Str('')
  declare name: string;
  @Str('')
  declare created_at: string | undefined;
  @Str('')
  declare updated_at: string | undefined;
  // relations
  @HasMany(() => Labour, 'supplier_id', 'id')
  declare labours: Labour;
  @HasMany(() => Plant, 'supplier_id', 'id')
  declare plants: Plant;
  @HasMany(() => ProjectSupplier, 'supplier_id', 'id')
  declare projects: ProjectSupplier[];
  /* --- user code --- */
  /* --- end user code --- */
}
