const Ziggy = {
  url: import.meta.env.VITE_APP_URL || 'http://localhost',
  port: undefined,
  defaults: {},
  routes: {
    'api.v1.company.projects.store': {
      uri: 'api/v1/companies/{company}/projects',
      methods: ['POST'],
      parameters: ['company'],
      bindings: { company: 'id' },
    },

    'api.v1.company.projects.destroy': {
      uri: 'api/v1/companies/{company}/projects/{project}',
      methods: ['DELETE'],
      parameters: ['company', 'project'],
      bindings: { company: 'id', project: 'id' },
    },

    'api.v1.companies.users.attach': {
      uri: 'api/v1/companies/{company}/projects/{project}/users',
      methods: ['POST'],
      parameters: ['company', 'project'],
      bindings: { company: 'id', project: 'id' },
    },

    'api.v1.companies.users.detach': {
      uri: 'api/v1/companies/{company}/projects/{project}/users',
      methods: ['DELETE'],
      parameters: ['company', 'project'],
      bindings: { company: 'id', project: 'id' },
    },

    'api.v1.companies.users.index': {
      uri: 'api/v1/companies/{company}/users',
      methods: ['GET', 'HEAD'],
      parameters: ['company'],
      bindings: { company: 'id' },
    },

    'api.v1.company.project-supplier.store': {
      uri: 'api/v1/companies/{company}/project-supplier',
      methods: ['POST'],
      parameters: ['company'],
      bindings: { company: 'id' },
    },

    'api.v1.company.project-supplier.clone': {
      uri: 'api/v1/companies/{company}/project-suppliers/clone',
      methods: ['POST'],
      parameters: ['company'],
      bindings: { company: 'id' },
    },

    'api.v1.company.project-supplier.update': {
      uri: 'api/v1/companies/project-supplier/{project_supplier}',
      methods: ['PUT', 'PATCH'],
      parameters: ['project_supplier'],
      bindings: { project_supplier: 'id' },
    },

    'api.v1.company.project-supplier.destroy': {
      uri: 'api/v1/companies/project-supplier/{project_supplier}',
      methods: ['DELETE'],
      parameters: ['project_supplier'],
      bindings: { project_supplier: 'id' },
    },

    'api.v1.projects.wbs_code.average': {
      uri: 'api/v1/projects/{project}/average',
      methods: ['GET', 'HEAD'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.projects.plant-utilisation': {
      uri: 'api/v1/projects/{project}/plant-utilisation',
      methods: ['GET', 'HEAD'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.projects.index': { uri: 'api/v1/projects', methods: ['GET', 'HEAD'] },
    'api.v1.projects.store': { uri: 'api/v1/projects', methods: ['POST'] },
    'api.v1.projects.show': {
      uri: 'api/v1/projects/{project}',
      methods: ['GET', 'HEAD'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.projects.update': {
      uri: 'api/v1/projects/{project}',
      methods: ['PUT', 'PATCH'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.projects.destroy': {
      uri: 'api/v1/projects/{project}',
      methods: ['DELETE'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.projects.generate': { uri: 'api/v1/projects/generate', methods: ['POST'] },
    'api.v1.projects.lock-boq': {
      uri: 'api/v1/projects/{project}/lock-boq',
      methods: ['POST'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.projects.unlock-boq': {
      uri: 'api/v1/projects/{project}/unlock-boq',
      methods: ['POST'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.labours.daily': {
      uri: 'api/v1/daily/labour/{project}',
      methods: ['GET', 'HEAD'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.labours.show': {
      uri: 'api/v1/labours/{labour}',
      methods: ['GET', 'HEAD'],
      parameters: ['labour'],
      bindings: { labour: 'id' },
    },

    'api.v1.labours.update': {
      uri: 'api/v1/labours/{labour}',
      methods: ['PUT', 'PATCH'],
      parameters: ['labour'],
      bindings: { labour: 'id' },
    },

    'api.v1.labours.destroy': {
      uri: 'api/v1/labours/{labour}',
      methods: ['DELETE'],
      parameters: ['labour'],
      bindings: { labour: 'id' },
    },

    'api.v1.plants.hire': {
      uri: 'api/v1/plants/{plant}/hire',
      methods: ['PATCH'],
      parameters: ['plant'],
      bindings: { plant: 'id' },
    },

    'api.v1.plants.daily': {
      uri: 'api/v1/daily/plant/{project}',
      methods: ['GET', 'HEAD'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.plants.show': {
      uri: 'api/v1/plants/{plant}',
      methods: ['GET', 'HEAD'],
      parameters: ['plant'],
      bindings: { plant: 'id' },
    },

    'api.v1.plants.update': {
      uri: 'api/v1/plants/{plant}',
      methods: ['PUT', 'PATCH'],
      parameters: ['plant'],
      bindings: { plant: 'id' },
    },

    'api.v1.plants.destroy': {
      uri: 'api/v1/plants/{plant}',
      methods: ['DELETE'],
      parameters: ['plant'],
      bindings: { plant: 'id' },
    },

    'api.v1.labours.index': {
      uri: 'api/v1/{project}/labours',
      methods: ['GET', 'HEAD'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.labours.store': {
      uri: 'api/v1/{project}/labours',
      methods: ['POST'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.plants.index': {
      uri: 'api/v1/{project}/plants',
      methods: ['GET', 'HEAD'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.plants.store': {
      uri: 'api/v1/{project}/plants',
      methods: ['POST'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.plant.daily.records.approve': {
      uri: 'api/v1/records/plant/{record}/approve',
      methods: ['POST'],
      parameters: ['record'],
      bindings: { record: 'id' },
    },

    'api.v1.plant.daily.records.unapprove': {
      uri: 'api/v1/records/plant/{record}/unapprove',
      methods: ['POST'],
      parameters: ['record'],
      bindings: { record: 'id' },
    },

    'api.v1.plant.daily.records.paid': {
      uri: 'api/v1/records/plant/{record}/paid',
      methods: ['POST'],
      parameters: ['record'],
      bindings: { record: 'id' },
    },

    'api.v1.plant.daily.records.split': {
      uri: 'api/v1/records/plant/{record}/split',
      methods: ['POST'],
      parameters: ['record'],
      bindings: { record: 'id' },
    },

    'api.v1.plant.daily.records.comments': {
      uri: 'api/v1/records/plant/{record}/comments',
      methods: ['POST'],
      parameters: ['record'],
      bindings: { record: 'id' },
    },

    'api.v1.plant.daily.records.vendor': {
      uri: 'api/v1/records/plant/{record}/vendor',
      methods: ['POST'],
      parameters: ['record'],
      bindings: { record: 'id' },
    },

    'api.v1.plant.daily.records.show': {
      uri: 'api/v1/records/plant/records/{record}',
      methods: ['GET', 'HEAD'],
      parameters: ['record'],
      bindings: { record: 'id' },
    },

    'api.v1.plant.daily.records.update': {
      uri: 'api/v1/records/plant/records/{record}',
      methods: ['PUT', 'PATCH'],
      parameters: ['record'],
      bindings: { record: 'id' },
    },

    'api.v1.plant.daily.records.destroy': {
      uri: 'api/v1/records/plant/records/{record}',
      methods: ['DELETE'],
      parameters: ['record'],
      bindings: { record: 'id' },
    },

    'api.v1.plant.daily.records.index': {
      uri: 'api/v1/records/plant/{plant}/records',
      methods: ['GET', 'HEAD'],
      parameters: ['plant'],
      bindings: { plant: 'id' },
    },

    'api.v1.plant.daily.records.store': {
      uri: 'api/v1/records/plant/{plant}/records',
      methods: ['POST'],
      parameters: ['plant'],
      bindings: { plant: 'id' },
    },

    'api.v1.labour.daily.records.approve': {
      uri: 'api/v1/records/labour/{record}/approve',
      methods: ['POST'],
      parameters: ['record'],
      bindings: { record: 'id' },
    },

    'api.v1.labour.daily.records.unapprove': {
      uri: 'api/v1/records/labour/{record}/unapprove',
      methods: ['POST'],
      parameters: ['record'],
      bindings: { record: 'id' },
    },

    'api.v1.labour.daily.records.paid': {
      uri: 'api/v1/records/labour/{record}/paid',
      methods: ['POST'],
      parameters: ['record'],
      bindings: { record: 'id' },
    },

    'api.v1.labour.daily.records.split': {
      uri: 'api/v1/records/labour/{record}/split',
      methods: ['POST'],
      parameters: ['record'],
      bindings: { record: 'id' },
    },

    'api.v1.labour.daily.records.comments': {
      uri: 'api/v1/records/labour/{record}/comments',
      methods: ['POST'],
      parameters: ['record'],
      bindings: { record: 'id' },
    },

    'api.v1.labour.daily.records.vendor': {
      uri: 'api/v1/records/labour/{record}/vendor',
      methods: ['POST'],
      parameters: ['record'],
      bindings: { record: 'id' },
    },

    'api.v1.labour.daily.records.show': {
      uri: 'api/v1/records/labour/records/{record}',
      methods: ['GET', 'HEAD'],
      parameters: ['record'],
      bindings: { record: 'id' },
    },

    'api.v1.labour.daily.records.update': {
      uri: 'api/v1/records/labour/records/{record}',
      methods: ['PUT', 'PATCH'],
      parameters: ['record'],
      bindings: { record: 'id' },
    },

    'api.v1.labour.daily.records.destroy': {
      uri: 'api/v1/records/labour/records/{record}',
      methods: ['DELETE'],
      parameters: ['record'],
      bindings: { record: 'id' },
    },

    'api.v1.labour.daily.records.index': {
      uri: 'api/v1/records/labour/{labour}/records',
      methods: ['GET', 'HEAD'],
      parameters: ['labour'],
      bindings: { labour: 'id' },
    },

    'api.v1.labour.daily.records.store': {
      uri: 'api/v1/records/labour/{labour}/records',
      methods: ['POST'],
      parameters: ['labour'],
      bindings: { labour: 'id' },
    },

    'api.v1.users.index': { uri: 'api/v1/users', methods: ['GET', 'HEAD'] },
    'api.v1.users.store': { uri: 'api/v1/users', methods: ['POST'] },
    'api.v1.users.show': {
      uri: 'api/v1/users/{user}',
      methods: ['GET', 'HEAD'],
      parameters: ['user'],
      bindings: { user: 'id' },
    },

    'api.v1.users.update': {
      uri: 'api/v1/users/{user}',
      methods: ['PUT', 'PATCH'],
      parameters: ['user'],
      bindings: { user: 'id' },
    },

    'api.v1.users.destroy': {
      uri: 'api/v1/users/{user}',
      methods: ['DELETE'],
      parameters: ['user'],
      bindings: { user: 'id' },
    },

    'api.v1.users.toggle-disable': {
      uri: 'api/v1/users/{user}/toggle-disable',
      methods: ['POST'],
      parameters: ['user'],
      bindings: { user: 'id' },
    },

    'api.v1.roles.index': { uri: 'api/v1/roles', methods: ['GET', 'HEAD'] },
    'api.v1.wbs-codes.store': {
      uri: 'api/v1/project/{project}/wbs-codes',
      methods: ['POST'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.variation-orders.index': {
      uri: 'api/v1/project/{project}/variation-orders',
      methods: ['GET', 'HEAD'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.variation-orders.store': {
      uri: 'api/v1/project/{project}/variation-orders',
      methods: ['POST'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.quantities.query': {
      uri: 'api/v1/project/{project}/quantities/query',
      methods: ['GET', 'HEAD'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.quantities.forecast': {
      uri: 'api/v1/project/{project}/quantities/forecast',
      methods: ['GET', 'HEAD'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.quantities.to-complete': {
      uri: 'api/v1/project/{project}/quantities/to-complete',
      methods: ['GET', 'HEAD'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.quantities.index': {
      uri: 'api/v1/project/{project}/quantities',
      methods: ['GET', 'HEAD'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.quantities.store': {
      uri: 'api/v1/project/{project}/quantities',
      methods: ['POST'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.surveyed.index': {
      uri: 'api/v1/project/{project}/quantities/surveyed',
      methods: ['GET', 'HEAD'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.surveyed.store': {
      uri: 'api/v1/project/{project}/quantities/surveyed',
      methods: ['POST'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.project-supplier.store': {
      uri: 'api/v1/project/{project}/project-supplier',
      methods: ['POST'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.asset-type.store': {
      uri: 'api/v1/project/{project}/asset-type',
      methods: ['POST'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.plant-categories.store': {
      uri: 'api/v1/project/{project}/plant-categories',
      methods: ['POST'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.trade-items.index': {
      uri: 'api/v1/project/{project}/trade-items',
      methods: ['GET', 'HEAD'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.trade-items.store': {
      uri: 'api/v1/project/{project}/trade-items',
      methods: ['POST'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.claims.index': {
      uri: 'api/v1/project/{project}/claims',
      methods: ['GET', 'HEAD'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.claims.store': {
      uri: 'api/v1/project/{project}/claims',
      methods: ['POST'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.labour.daily.records.search': {
      uri: 'api/v1/project/{project}/records/labour/search',
      methods: ['GET', 'HEAD'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.plant.daily.records.search': {
      uri: 'api/v1/project/{project}/records/plant/search',
      methods: ['GET', 'HEAD'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.trades.index': {
      uri: 'api/v1/project/{project}/trades',
      methods: ['GET', 'HEAD'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.trades.store': {
      uri: 'api/v1/project/{project}/trades',
      methods: ['POST'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.trades.seed': {
      uri: 'api/v1/project/{project}/trades/seed',
      methods: ['POST'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.projects.plant-utilisation-metrics': {
      uri: 'api/v1/project/{project}/plant-utilisation-metrics',
      methods: ['GET', 'HEAD'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.projects.self-perform-metrics': {
      uri: 'api/v1/project/{project}/self-perform-metrics',
      methods: ['GET', 'HEAD'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.projects.weekly-performance-metrics': {
      uri: 'api/v1/project/{project}/weekly-performance-metrics',
      methods: ['GET', 'HEAD'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.projects.head-contract-settings': {
      uri: 'api/v1/project/{project}/head-contract-settings',
      methods: ['PATCH'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.trade-item-costs.store': {
      uri: 'api/v1/claims/lite/{claim}/trade-items/{trade_item}/certified',
      methods: ['POST'],
      parameters: ['claim', 'trade_item'],
      bindings: { claim: 'id', trade_item: 'id' },
    },

    'api.v1.variation-order-costs.store': {
      uri: 'api/v1/claims/lite/{claim}/variation-orders/{variation_order}/certified',
      methods: ['POST'],
      parameters: ['claim', 'variation_order'],
      bindings: { claim: 'id', variation_order: 'id' },
    },

    'api.v1.claims.lite.certify': {
      uri: 'api/v1/claims/lite/{claim}/certify',
      methods: ['POST'],
      parameters: ['claim'],
      bindings: { claim: 'id' },
    },

    'api.v1.claims.lite.export': {
      uri: 'api/v1/claims/lite/{claim}/export',
      methods: ['POST'],
      parameters: ['claim'],
      bindings: { claim: 'id' },
    },

    'api.v1.variation-order-costs.update': {
      uri: 'api/v1/variation-order-costs/{variation_order_cost}',
      methods: ['PUT', 'PATCH'],
      parameters: ['variation_order_cost'],
      bindings: { variation_order_cost: 'id' },
    },

    'api.v1.variation-order-costs.destroy': {
      uri: 'api/v1/variation-order-costs/{variation_order_cost}',
      methods: ['DELETE'],
      parameters: ['variation_order_cost'],
      bindings: { variation_order_cost: 'id' },
    },

    'api.v1.trade-item-costs.update': {
      uri: 'api/v1/trade-item-costs/{trade_item_cost}',
      methods: ['PUT', 'PATCH'],
      parameters: ['trade_item_cost'],
      bindings: { trade_item_cost: 'id' },
    },

    'api.v1.trade-item-costs.destroy': {
      uri: 'api/v1/trade-item-costs/{trade_item_cost}',
      methods: ['DELETE'],
      parameters: ['trade_item_cost'],
      bindings: { trade_item_cost: 'id' },
    },

    'api.v1.wbs-codes.update': {
      uri: 'api/v1/wbs-codes/{wbs_code}',
      methods: ['PUT', 'PATCH'],
      parameters: ['wbs_code'],
      bindings: { wbs_code: 'id' },
    },

    'api.v1.wbs-codes.destroy': {
      uri: 'api/v1/wbs-codes/{wbs_code}',
      methods: ['DELETE'],
      parameters: ['wbs_code'],
      bindings: { wbs_code: 'id' },
    },

    'api.v1.variation-orders.update': {
      uri: 'api/v1/variation-orders/{variation_order}',
      methods: ['PUT', 'PATCH'],
      parameters: ['variation_order'],
      bindings: { variation_order: 'id' },
    },

    'api.v1.variation-orders.destroy': {
      uri: 'api/v1/variation-orders/{variation_order}',
      methods: ['DELETE'],
      parameters: ['variation_order'],
      bindings: { variation_order: 'id' },
    },

    'api.v1.quantities.update': {
      uri: 'api/v1/quantities/{quantity}',
      methods: ['PUT', 'PATCH'],
      parameters: ['quantity'],
      bindings: { quantity: 'id' },
    },

    'api.v1.quantities.destroy': {
      uri: 'api/v1/quantities/{quantity}',
      methods: ['DELETE'],
      parameters: ['quantity'],
      bindings: { quantity: 'id' },
    },

    'api.v1.surveyed.update': {
      uri: 'api/v1/quantities/surveyed/{surveyed}',
      methods: ['PUT', 'PATCH'],
      parameters: ['surveyed'],
      bindings: { surveyed: 'id' },
    },

    'api.v1.project-supplier.update': {
      uri: 'api/v1/project-supplier/{project_supplier}',
      methods: ['PUT', 'PATCH'],
      parameters: ['project_supplier'],
      bindings: { project_supplier: 'id' },
    },

    'api.v1.project-supplier.destroy': {
      uri: 'api/v1/project-supplier/{project_supplier}',
      methods: ['DELETE'],
      parameters: ['project_supplier'],
      bindings: { project_supplier: 'id' },
    },

    'api.v1.asset-type.update': {
      uri: 'api/v1/asset-type/{asset_type}',
      methods: ['PUT', 'PATCH'],
      parameters: ['asset_type'],
      bindings: { asset_type: 'id' },
    },

    'api.v1.asset-type.destroy': {
      uri: 'api/v1/asset-type/{asset_type}',
      methods: ['DELETE'],
      parameters: ['asset_type'],
      bindings: { asset_type: 'id' },
    },

    'api.v1.plant-categories.update': {
      uri: 'api/v1/plant-categories/{plant_category}',
      methods: ['PUT', 'PATCH'],
      parameters: ['plant_category'],
      bindings: { plant_category: 'id' },
    },

    'api.v1.plant-categories.destroy': {
      uri: 'api/v1/plant-categories/{plant_category}',
      methods: ['DELETE'],
      parameters: ['plant_category'],
      bindings: { plant_category: 'id' },
    },

    'api.v1.claims.show': {
      uri: 'api/v1/claims/{claim}',
      methods: ['GET', 'HEAD'],
      parameters: ['claim'],
      bindings: { claim: 'id' },
    },

    'api.v1.claims.update': {
      uri: 'api/v1/claims/{claim}',
      methods: ['PUT', 'PATCH'],
      parameters: ['claim'],
      bindings: { claim: 'id' },
    },

    'api.v1.claims.destroy': {
      uri: 'api/v1/claims/{claim}',
      methods: ['DELETE'],
      parameters: ['claim'],
      bindings: { claim: 'id' },
    },

    'api.v1.claims.send.draft': {
      uri: 'api/v1/claims/{claim}/send',
      methods: ['POST'],
      parameters: ['claim'],
      bindings: { claim: 'id' },
    },

    'api.v1.trades.update': {
      uri: 'api/v1/trades/{trade}',
      methods: ['PUT', 'PATCH'],
      parameters: ['trade'],
      bindings: { trade: 'id' },
    },

    'api.v1.trades.destroy': {
      uri: 'api/v1/trades/{trade}',
      methods: ['DELETE'],
      parameters: ['trade'],
      bindings: { trade: 'id' },
    },

    'api.v1.trades.sort': {
      uri: 'api/v1/trades/{trade}/sort',
      methods: ['PATCH'],
      parameters: ['trade'],
      bindings: { trade: 'id' },
    },

    'api.v1.trades.baseline-forecast': {
      uri: 'api/v1/trades/{trade}/baseline-forecast',
      methods: ['PATCH'],
      parameters: ['trade'],
      bindings: { trade: 'id' },
    },

    'api.v1.trades.forecast': {
      uri: 'api/v1/trades/{trade}/forecast',
      methods: ['PATCH'],
      parameters: ['trade'],
      bindings: { trade: 'id' },
    },

    'api.v1.trade-items.update': {
      uri: 'api/v1/trade-items/{trade_item}',
      methods: ['PUT', 'PATCH'],
      parameters: ['trade_item'],
      bindings: { trade_item: 'id' },
    },

    'api.v1.trade-items.destroy': {
      uri: 'api/v1/trade-items/{trade_item}',
      methods: ['DELETE'],
      parameters: ['trade_item'],
      bindings: { trade_item: 'id' },
    },

    'api.v1.trade-items.sort': {
      uri: 'api/v1/trade-items/{trade_item}/sort',
      methods: ['PATCH'],
      parameters: ['trade_item'],
      bindings: { trade_item: 'id' },
    },

    'api.v1.companies.update': {
      uri: 'api/v1/companies/{company}',
      methods: ['PUT', 'PATCH'],
      parameters: ['company'],
      bindings: { company: 'id' },
    },

    'api.v1.suppliers.search': { uri: 'api/v1/search/suppliers', methods: ['GET', 'HEAD'] },
    'api.v1.admin.projects.index': { uri: 'api/v1/admin/projects', methods: ['GET', 'HEAD'] },
    'api.v1.admin.projects.search': { uri: 'api/v1/admin/projects/search', methods: ['GET', 'HEAD'] },
    'api.v1.admin.users.index': { uri: 'api/v1/admin/users', methods: ['GET', 'HEAD'] },
    'api.v1.admin.projects.restore': {
      uri: 'api/v1/admin/projects/{project}/restore',
      methods: ['PATCH'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.admin.projects.force-delete': {
      uri: 'api/v1/admin/projects/{project}/force-delete',
      methods: ['DELETE'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.admin.companies.index': { uri: 'api/v1/admin/companies', methods: ['GET', 'HEAD'] },
    'api.v1.admin.companies.store': { uri: 'api/v1/admin/companies', methods: ['POST'] },
    'api.v1.admin.companies.show': {
      uri: 'api/v1/admin/companies/{company}',
      methods: ['GET', 'HEAD'],
      parameters: ['company'],
      bindings: { company: 'id' },
    },

    'api.v1.admin.companies.update': {
      uri: 'api/v1/admin/companies/{company}',
      methods: ['PUT', 'PATCH'],
      parameters: ['company'],
      bindings: { company: 'id' },
    },

    'api.v1.admin.companies.destroy': {
      uri: 'api/v1/admin/companies/{company}',
      methods: ['DELETE'],
      parameters: ['company'],
      bindings: { company: 'id' },
    },

    'api.v1.admin.claims.index': { uri: 'api/v1/admin/claims', methods: ['GET', 'HEAD'] },
    'api.v1.admin.claims.show': {
      uri: 'api/v1/admin/claims/{claim}',
      methods: ['GET', 'HEAD'],
      parameters: ['claim'],
      bindings: { claim: 'id' },
    },

    'api.v1.admin.claims.destroy': {
      uri: 'api/v1/admin/claims/{claim}',
      methods: ['DELETE'],
      parameters: ['claim'],
      bindings: { claim: 'id' },
    },

    'api.v1.claims.certified.store': {
      uri: 'api/v1/claims/{claim}/certified',
      methods: ['POST'],
      parameters: ['claim'],
      bindings: { claim: 'id' },
    },

    'api.v1.claims.claim.store': {
      uri: 'api/v1/claims/{claim}/claim',
      methods: ['POST'],
      parameters: ['claim'],
      bindings: { claim: 'id' },
    },

    'api.v1.claims.variation.claim.store': {
      uri: 'api/v1/claims/{claim}/variation-order/claim',
      methods: ['POST'],
      parameters: ['claim'],
      bindings: { claim: 'id' },
    },

    'api.v1.claims.variation.certified.store': {
      uri: 'api/v1/claims/{claim}/variation-order/certified',
      methods: ['POST'],
      parameters: ['claim'],
      bindings: { claim: 'id' },
    },

    'api.v1.claims.send.pqs': {
      uri: 'api/claims/{claim}/send/pqs',
      methods: ['POST'],
      parameters: ['claim'],
      bindings: { claim: 'id' },
    },

    'api.v1.claims.send.contractor': {
      uri: 'api/claims/{claim}/send/contractor',
      methods: ['POST'],
      parameters: ['claim'],
      bindings: { claim: 'id' },
    },

    'api.v1.claims.certify': {
      uri: 'api/claims/{claim}/send/certify',
      methods: ['POST'],
      parameters: ['claim'],
      bindings: { claim: 'id' },
    },

    'api.v1.me': { uri: 'api/v1/user', methods: ['GET', 'HEAD'] },
    'api.v1.projects.export': {
      uri: 'exports/{project}/tracker',
      methods: ['GET', 'HEAD'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.quantities.cost-at-complete-export': {
      uri: 'exports/{project}/cost-at-complete',
      methods: ['GET', 'HEAD'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },

    'api.v1.quantities.export': {
      uri: 'exports/{project}/quantities',
      methods: ['GET', 'HEAD'],
      parameters: ['project'],
      bindings: { project: 'id' },
    },
  },
} as const;

export { Ziggy };
