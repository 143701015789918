import { Model } from 'pinia-orm';
import { MorphTo, Num, Str } from 'pinia-orm/dist/decorators';
import LabourDailyRecord from './LabourDailyRecord';
import PlantDailyRecord from './PlantDailyRecord';
import User from './User';
/* --- user header --- */
/* --- end user header --- */

export default class Comment extends Model {
  static entity = 'App\\Models\\Comments';
  // fields
  @Num(0)
  declare id: number;
  @Str('')
  declare body: string;
  @Str('')
  declare commentable_type: string;
  @Num(0)
  declare commentable_id: number;
  @Str('')
  declare creator_type: string;
  @Num(0)
  declare creator_id: number;
  @Str('')
  declare created_at: string | undefined;
  @Str('')
  declare updated_at: string | undefined;
  // relations
  @MorphTo(() => [LabourDailyRecord, PlantDailyRecord], 'commentable_id', 'commentable_type')
  declare commentable: LabourDailyRecord | PlantDailyRecord;
  @MorphTo(() => [User], 'creator_id', 'creator_type')
  declare creator: User;
  /* --- user code --- */

  public get createdAt(): Date {
    return new Date(this.created_at);
  }

  /* --- end user code --- */
}
