import WbsCode from '@/models/WbsCode';
import WbsCodeService from '@/services/WbsCodeService';
import { defineStore } from 'pinia';
import { useRepo } from 'pinia-orm';

export const useWbsCodesStore = defineStore('wbs_codes', {
  state: () => ({}),

  getters: {
    models: () => useRepo(WbsCode),
  },

  actions: {
    async createCode(projectId: Id, request: ApiRequests['api.v1.wbs-codes.store']) {
      const [data, error] = await WbsCodeService.create(projectId, request);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async updateCode(projectId: Id, request: ApiRequests['api.v1.wbs-codes.update']) {
      const [data, error] = await WbsCodeService.update(projectId, request);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async deleteCode(id: Id) {
      const [data, error] = await WbsCodeService.delete(id);

      if(data) {
        this.models.destroy(data.id);

        return data;
      }

      throw error;
    },
  },
});
