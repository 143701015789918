import { Model } from 'pinia-orm';
import { Attr, BelongsTo, Num, Str } from 'pinia-orm/dist/decorators';
import Company from './Company';
import Project from './Project';
import Supplier from './Supplier';
/* --- user header --- */
/* --- end user header --- */

export const SupplierType = {
  labour: 0,
  plant: 1,
  material: 2,
} as const;

export default class ProjectSupplier extends Model {
  static entity = 'App\\Models\\ProjectSupplier';
  // fields
  @Num(0)
  declare id: number;
  @Attr(undefined)
  declare project_id: number | undefined;
  @Attr(undefined)
  declare company_id: number | undefined;
  @Num(0)
  declare supplier_id: number;
  @Attr(undefined)
  declare overtime: { threshold: number; multiplier: number }[] | undefined;
  @Num(0)
  declare saturday_multiplier: number;
  @Num(0)
  declare sunday_multiplier: number;
  @Num(0)
  declare public_holiday_multiplier: number;
  @Str('')
  declare created_at: string | undefined;
  @Str('')
  declare updated_at: string | undefined;
  @Num(undefined)
  declare type: ObjectValues<typeof SupplierType>;
  @Str('')
  declare address: string | undefined;
  @Str('')
  declare contact_number: string | undefined;
  @Str('')
  declare contact_name: string | undefined;
  @Str('')
  declare contact_email: string | undefined;
  // relations
  @BelongsTo(() => Project, 'project_id', 'id')
  declare project: Project;
  @BelongsTo(() => Supplier, 'supplier_id', 'id')
  declare supplier: Supplier;
  @BelongsTo(() => Company, 'company_id', 'id')
  declare company: Company;
  /* --- user code --- */
  /* --- end user code --- */
}
