import { route } from '@/ziggy-shim';
import { api, CastValue } from './LaravelClient';

export default {
  async create(projectId: Id, data: ApiRequests['api.v1.wbs-codes.store']) {
    return await api(route('api.v1.wbs-codes.store', projectId), 'post', {
      data,
    });
  },

  async update(id: Id, data: ApiRequests['api.v1.wbs-codes.update']) {
    return await api(route('api.v1.wbs-codes.update', id), 'patch', {
      data,
      casts: {
        final_quantity: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
        to_complete_production_rate: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
      },
    });
  },

  async delete(id: Id) {
    return await api(route('api.v1.wbs-codes.destroy', id), 'delete');
  },
};
