import VariationOrder from '@/models/VariationOrder';
import VariationOrderService from '@/services/VariationOrderService';
import { defineStore } from 'pinia';
import { useRepo } from 'pinia-orm';

export const useVariationOrdersStore = defineStore('variation_orders', {
  state: () => ({}),

  getters: {
    models: () => useRepo(VariationOrder),
  },

  actions: {
    async fetchVariationOrders(projectId: Id) {
      const [data, error] = await VariationOrderService.index(projectId);

      if(data) {
        this.models.save(data.data);

        return data;
      }

      throw error;
    },

    async createVariationOrder(projectId: Id, request: ApiRequests['api.v1.variation-orders.store']) {
      const [data, error] = await VariationOrderService.create(projectId, request);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async updateVariationOrder(projectId: Id, request: ApiRequests['api.v1.variation-orders.update']) {
      const [data, error] = await VariationOrderService.update(projectId, request);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async deleteVariationOrder(id: Id) {
      const [data, error] = await VariationOrderService.delete(id);

      if(data) {
        this.models.destroy(data.id);

        return data;
      }

      throw error;
    },
  },
});
