import { Model } from 'pinia-orm';
import { Attr, BelongsTo, Num, Str } from 'pinia-orm/dist/decorators';
import Company from './Company';

export default class Permissions extends Model {
  static primaryKey = ['id'];
  static entity = 'orm/Permissions';
  @Num(0)
  declare id: number;
  @Attr(undefined)
  declare project_id: number;
  @Attr(undefined)
  declare company_id: number;
  @Num(0)
  declare user_id: number;
  @Num(0)
  declare role_id: number;
  @Str('')
  declare created_at: string;
  @Str('')
  declare updated_at: string;

  @BelongsTo(() => Company, 'company_id', 'id')
  declare company: Company;
}
