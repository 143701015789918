import { defineStore } from 'pinia';
import { RouteRecordNormalized } from 'vue-router';
import { RouteLocationRaw, RouteRecordName } from 'vue-router';

interface State {
  backRoute?: RouteLocationRaw;
}

export const useNavStore = defineStore('nav', {
  state: (): State => ({
    backRoute: undefined,
  }),

  getters: {
    routesAsMap(): Map<RouteRecordName, RouteRecordNormalized> {
      const routeMap = new Map<RouteRecordName, RouteRecordNormalized>();

      this.router.getRoutes().forEach((route) => {
        routeMap.set(route.name, route);
      });

      return routeMap;
    },
  },

  actions: {
    consumeBackRoute() {
      const route = this.backRoute;

      this.backRoute = undefined;

      return route as RouteLocationRaw;
    },
  },
});
