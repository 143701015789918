import { Model } from 'pinia-orm';
import { BelongsToMany, Num, Str } from 'pinia-orm/dist/decorators';
import Permissions from './Permissions';
import User from './User';
/* --- user header --- */
/* --- end user header --- */

export default class Role extends Model {
  static entity = 'App\\Models\\Role';
  // fields
  @Num(0)
  declare id: number;
  @Str('')
  declare name: RoleTypes;
  @Str('')
  declare descriptor: string;
  @Str('')
  declare created_at: string | undefined;
  @Str('')
  declare updated_at: string | undefined;
  // relations
  @BelongsToMany(() => User, () => Permissions, 'role_id', 'user_id', 'id', 'id')
  declare users: User[];
  /* --- user code --- */
  /* --- end user code --- */
}
