import { Model } from 'pinia-orm';
import { Attr, BelongsTo, Bool, HasMany, HasOne, Num, Str } from 'pinia-orm/dist/decorators';
import PlantDailyRecord from './PlantDailyRecord';
import PlantHirePeriod from './PlantHirePeriod';
import Project from './Project';
import Supplier from './Supplier';
/* --- user header --- */
import { dateAsYMD } from '@/helpers';
import PlantCategory from './PlantCategory';
/* --- end user header --- */

export default class Plant extends Model {
  static entity = 'App\\Models\\Plant';
  // fields
  @Num(0)
  declare id: number;
  @Str('')
  declare fleet: string;
  @Num(0)
  declare supplier_id: number;
  @Num(0)
  declare project_id: number;
  @Num(0)
  declare rate: number;
  @Bool(false)
  declare wet: boolean;
  @Bool(false)
  declare pay_standby: boolean;
  @Bool(false)
  declare smu_tracking: boolean;
  @Str('')
  declare description: string | undefined;
  @Str('')
  declare created_at: string | undefined;
  @Str('')
  declare updated_at: string | undefined;
  @Str('')
  declare deleted_at: string | undefined;
  @Num(0)
  declare plant_category_id: number | undefined;
  // relations
  @HasMany(() => PlantDailyRecord, 'plant_id', 'id')
  declare records: PlantDailyRecord[];
  @BelongsTo(() => Project, 'project_id', 'id')
  declare project: Project;
  @BelongsTo(() => Supplier, 'supplier_id', 'id')
  declare supplier: Supplier;
  @HasMany(() => PlantHirePeriod, 'plant_id', 'id')
  declare hire_period: PlantHirePeriod[];
  @HasOne(() => PlantHirePeriod, 'plant_id', 'id')
  declare on_hired: PlantHirePeriod;
  @BelongsTo(() => PlantCategory, 'plant_category_id', 'id')
  declare plant_category: PlantCategory;

  /* --- user code --- */
  // Computed Properties
  @Attr(undefined)
  declare $total_hours: number | undefined;
  @Attr(undefined)
  declare $total_days: number | undefined;
  @Attr(undefined)
  declare $paid_to_date: number | undefined;
  @Attr(undefined)
  declare $total_accrual: number | undefined;
  @Attr(undefined)
  declare $total_standby: number | undefined;
  @Attr(undefined)
  declare $total_breakdown: number | undefined;

  get totalHours() {
    return this.$total_hours || this.records?.reduce((total, value) => total + value.hours, 0) || 0;
  }

  get totalDays() {
    return this.$total_days || this.records.length || 0;
  }

  get paidToDate() {
    return this.$paid_to_date || this.records
      ?.filter((record) => record.paid)
      .reduce((total, value) => total + value.paid, 0) ||
      0;
  }

  get totalAccrual() {
    return this.$total_accrual || this.project && this.records
          ?.filter((record) => !record.paid)
          .reduce((total, value) => {
            if(this.wet) {
              return total + value.accrual_rate * value.hours;
            }

            const dailyRecords = this.records.filter((plantRecord) => {
              return plantRecord.day === value.day;
            });

            const dailyTotal = dailyRecords.reduce((recordtotal, plantRecord) => recordtotal + plantRecord.hours, 0);
            const percentage = value.hours / dailyTotal;

            return total + value.accrual_rate * percentage;
          }, 0) ||
      0;
  }

  get totalStandby() {
    return this.$total_standby || this.records
      ?.reduce((total, value) => {
        return total + value.standdown;
      }, 0) ||
      0;
  }

  get totalBreakdown() {
    return this.$total_breakdown || this.records
      ?.reduce((total, value) => {
        return total + value.breakdown;
      }, 0) ||
      0;
  }

  dailyHours(date: Date) {
    return this.records?.filter((record) => {
      return record.day === dateAsYMD(date);
    }).reduce((total, value) => total + value.hours, 0) || 0;
  }

  dailyRecords(date: Date) {
    return this.records?.filter((record) => {
      return record.day === dateAsYMD(date);
    });
  }
  /* --- end user code --- */
}
