<template>
  <component
    :is="asyncComponent"
  />
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';

const props = defineProps<{
  name: string;
}>();

const dynamicComponent = import(`./svg/${props.name}.svg?component`);
const asyncComponent = defineAsyncComponent(() => dynamicComponent);
</script>
