import { Model } from 'pinia-orm';
import { BelongsTo, HasMany, Num, Str } from 'pinia-orm/dist/decorators';
import DailyQuantityLoads from './DailyQuantityLoads';
import Project from './Project';
/* --- user header --- */
/* --- end user header --- */

export default class DailyQuantity extends Model {
  static entity = 'App\\Models\\DailyQuantity';
  // fields
  @Num(0)
  declare id: number;
  @Str('')
  declare day: string;
  @Num(0)
  declare quantity: number;
  @Num(undefined)
  declare bias: number | undefined;
  @Num(0)
  declare project_id: number;
  @Num(0)
  declare wbs_code_id: number;
  @Str('')
  declare created_at: string | undefined;
  @Str('')
  declare updated_at: string | undefined;
  // relations
  @BelongsTo(() => Project, 'project_id', 'id')
  declare project: Project;
  @HasMany(() => DailyQuantityLoads, 'daily_quantity_id', 'id')
  declare quantity_loads: DailyQuantityLoads[];
  /* --- user code --- */

  get surveyedQuantity(): number {
    return this.quantity + this.bias;
  }
  /* --- end user code --- */
}
