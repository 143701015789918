import { falsey } from '@/helpers';
import { Model, useRepo } from 'pinia-orm';
import { Attr, BelongsTo, HasMany, Num, Str } from 'pinia-orm/dist/decorators';
import Claim from './Claim';
import Project from './Project';
import VariationOrderCost from './VariationOrderCost';
/* --- user header --- */
/* --- end user header --- */

export const VariationOrderType = {
  variation: 0,
  provisional_sum: 1,
  time_extension: 2,
  other: 3,
} as const;

export const VariationOrderTitles = {
  variation: 'Variations',
  provisional_sum: 'Provisional Sums',
  time_extension: 'Extension of Time',
  other: 'Other',
} as const;

export default class VariationOrder extends Model {
  static entity = 'App\\Models\\VariationOrder';
  // fields
  @Num(0)
  declare id: number;
  @Str('')
  declare code: string;
  @Str('')
  declare description: string;
  @Num(0)
  declare project_id: number;
  @Str('')
  declare deleted_at: string | undefined;
  @Str('')
  declare created_at: string | undefined;
  @Str('')
  declare updated_at: string | undefined;
  @Num(0)
  declare budget: number;
  @Num(undefined)
  declare type: ObjectValues<typeof VariationOrderType>;
  // relations
  @BelongsTo(() => Project, 'project_id', 'id')
  declare project: Project;
  @HasMany(() => VariationOrderCost, 'variation_order_id', 'id')
  declare costs: VariationOrderCost[];

  @Attr(undefined)
  declare $previous: number | undefined;
  @Attr(undefined)
  declare $totalCertified: number | undefined;
  @Attr(undefined)
  declare $totalClaim: number | undefined;
  /* --- user code --- */

  public previous(claim: Claim) {
    if(!falsey(this.$previous)) {
      return this.$previous;
    }

    const previousCosts = useRepo(VariationOrderCost)
      .where('variation_order_id', this.id)
      .whereHas('claim', (query) => {
        query.where('id', (id) => {
          return id !== claim.id;
        })
          .where('end', (end) => {
            return end < claim.end;
          });
      }).get();

    let totalPrevious = 0;

    previousCosts.forEach((cost) => {
      totalPrevious += cost.current_certified;
    });

    return totalPrevious;
  }

  get totalClaim() {
    return this.$totalClaim;
  }

  get totalCertified() {
    return this.$totalCertified;
  }

  /* --- end user code --- */
}
