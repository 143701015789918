import { ref } from 'vue';

export const States = {
  ERROR: 1,
  LOADING: 2,
  COMPLETE: 3,
} as const;

type statesTypes = ObjectValues<typeof States>;

export function useStoreApiAction<T extends (...args: any[]) => Promise<any>>(action: T) {
  const loading = ref(false);
  const firstLoad = ref(false);
  const state = ref<statesTypes>();
  const requestTime = ref(0);

  const request = async (...params: Parameters<T>): Promise<Awaited<ReturnType<T>>> => {
    loading.value = true;
    state.value = States.LOADING;
    requestTime.value = 0;

    const requestTimeInterval = setInterval(() => {
      requestTime.value += 1;
    }, 1000);

    try {
      const data = await action(...params);

      loading.value = false;
      state.value = States.COMPLETE;
      firstLoad.value = true;

      return data;
    } catch (error) {
      loading.value = false;

      state.value = States.ERROR;

      throw error;
    } finally {
      clearInterval(requestTimeInterval);
    }
  };

  const is = (otherState: statesTypes) => {
    return state.value === otherState;
  };

  const isNot = (otherState: statesTypes) => {
    return state.value !== otherState;
  };

  return {
    loading,
    request,
    state,
    firstLoad,
    is,
    isNot,
    requestTime,
  };
}
