import Project from '@/models/Project';
import ProjectService from '@/services/ProjectService';
import { defineStore } from 'pinia';
import { useRepo } from 'pinia-orm';

export const useProjectsStore = defineStore('projects', {
  state: () => ({
    selectedProject: undefined,
  }),

  getters: {
    models: () => useRepo(Project),

    project(state): Project {
      return this.models.with('codes').with('orders').find(state.selectedProject);
    },
  },

  actions: {
    setProject(Id: Id, navigate = false) {
      this.selectedProject = Id;

      if(navigate) {
        this.router.push({ name: this.router.currentRoute.value.name, params: { projectId: Id } });
      }
    },

    async fetchProject(id: Id) {
      const [data, error] = await ProjectService.show(id);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async fetchProjects() {
      const [data, error] = await ProjectService.index();

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async updateProject(id: Id, form: ApiRequests['api.v1.projects.update']) {
      const [data, error] = await ProjectService.update(id, form);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async updateHeadContractSettings(id: Id, form: ApiRequests['api.v1.projects.head-contract-settings']) {
      const [data, error] = await ProjectService.updateHeadContractSettings(id, form);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async deleteProject(id: Id) {
      const [data, error] = await ProjectService.delete(id);

      if(data) {
        this.models.destroy(id);

        return data;
      }

      throw error;
    },

    async createProject(form: ApiRequests['api.v1.projects.store']) {
      const [data, error] = await ProjectService.create(form);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async createCompanyProject(companyId: Id, form: ApiRequests['api.v1.company.projects.store']) {
      const [data, error] = await ProjectService.companyCreate(companyId, form);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async wbsCodeAverage(id: Id, form: ApiRequests['api.v1.projects.wbs_code.average']) {
      const [data, error] = await ProjectService.wbsCodeAverage(id, form);

      if(data) {
        return data;
      }

      throw error;
    },

    async utlisationReport(id: Id, form: ApiRequests['api.v1.projects.plant-utilisation']) {
      const [data, error] = await ProjectService.utilisationReport(id, form);

      if(data) {
        return data;
      }

      throw error;
    },

    async selfPerformDashboard(
      id: Id,
      form: ApiRequests['api.v1.projects.plant-utilisation-metrics'] = { date: undefined },
    ) {
      const [data, error] = await ProjectService.plantUtilisationGraph(id, form);

      if(data) {
        return data;
      }

      throw error;
    },

    async selfPerformMetrics(id: Id, form: ApiRequests['api.v1.projects.self-perform-metrics'] = { date: undefined }) {
      const [data, error] = await ProjectService.selfPerformMetrics(id, form);

      if(data) {
        return data;
      }

      throw error;
    },

    async weeklyPerformanceGraph(id: Id) {
      const [data, error] = await ProjectService.weeklyPerformanceGraph(id);

      if(data) {
        return data;
      }

      throw error;
    },

    async generate() {
      const [data, error] = await ProjectService.generate();

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async fetchAdminProjects(page = 1) {
      const [data, error] = await ProjectService.adminIndex(page);

      if(data) {
        this.models.flush();
        this.models.save(data.projects.data);

        return data;
      }

      throw error;
    },

    async searchAdminProjects(request: ApiRequests['api.v1.admin.projects.search']) {
      const [data, error] = await ProjectService.admin.search(request);

      if(data) {
        return data;
      }

      throw error;
    },

    async lockBoQ(id: Id) {
      const [data, error] = await ProjectService.lockBoq(id);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async unlockBoq(id: Id) {
      const [data, error] = await ProjectService.unlockBoq(id);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async adminForceDeleteProject(id: Id) {
      const [data, error] = await ProjectService.admin.forceDelete(id);

      if(data) {
        this.models.destroy(id);

        return data;
      }

      throw error;
    },

    async adminRestoreProject(id: Id) {
      const [data, error] = await ProjectService.admin.restore(id);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },
  },

  persist: {
    storage: sessionStorage,
  },
});
