import { useFormData } from '@/composables/useFormData';
import Labour from '@/models/Labour';
import LabourService from '@/services/LabourService';
import { defineStore } from 'pinia';
import { useRepo } from 'pinia-orm';

export const useLaboursStore = defineStore('labours', {
  state: () => ({
    filter: useFormData({
      first_name: undefined,
      last_name: undefined,
      supplier_name: undefined,
      day_min: undefined,
      day_max: undefined,
      wbs_code_id: undefined,
      vendor_ref: undefined,
    }),

    active: false,
  }),

  getters: {
    models: () => useRepo(Labour),
  },

  actions: {
    resetFilter() {
      this.active = false;
      this.filter.resetData();
    },

    async fetchLabours(projectId: Id, form: ApiRequests['api.v1.labours.index'] = {}) {
      const [data, error] = await LabourService.index(projectId, form);

      if(data) {
        this.models.save(data.data);

        return data;
      }

      throw error;
    },

    async fetchLabour(id: Id) {
      const [data, error] = await LabourService.show(id);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async fetchDailyLabours(projectId: Id, day: ApiRequests['api.v1.plants.daily']) {
      const [data, error] = await LabourService.daily(projectId, day);

      if(data) {
        return this.models.save(data);
      }

      throw error;
    },

    async saveLabour(projectId: Id, labour: ApiRequests['api.v1.labours.store']) {
      const [data, error] = await LabourService.create(projectId, labour);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async updateLabour(id: Id, labour: ApiRequests['api.v1.labours.update']) {
      const [data, error] = await LabourService.update(id, labour);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async deleteLabour(id: Id) {
      const [data, error] = await LabourService.delete(id);

      if(data) {
        this.models.destroy(data.id);

        return data;
      }

      throw error;
    },
  },

  persist: {
    storage: sessionStorage,
  },
});
