import { inCapacitor, promiseWithResolver } from '@/helpers';
import Permissions from '@/models/Permissions';
import User from '@/models/User';
import AuthService from '@/services/AuthService';
import CapacitorService, { StoreKeys } from '@/services/CapacitorService';
import UserService, { UserCreateRequest, UserUpdateRequest } from '@/services/UserService';
import Bugsnag from '@bugsnag/js';
import { defineStore } from 'pinia';
import { useRepo } from 'pinia-orm';
import { useNavStore } from './nav';
import { useProjectsStore } from './projects';
import { useRolesStore } from './roles';

export const useUserStore = defineStore('user', {
  state: () => ({
    user: undefined,
    userFetched: promiseWithResolver(),
  }),

  getters: {
    authenticated: (state) => state.user !== undefined,
    isAdmin: (state) => {
      const permissionsRepo = useRepo(Permissions);

      return permissionsRepo.where('user_id', (state.user as User).id)
        .where('role_id', 1).get().length > 0;
    },

    isRole: (state) => (roles: RoleTypes[], includeAdmin = true, projectId: string | undefined = undefined) => {
      const projectsStore = useProjectsStore();

      if(!state.user) {
        return false;
      }

      const rolesStore = useRolesStore();

      const rolesToCheck = rolesStore.models.where('name', (name: RoleTypes) => {
        return roles.includes(name);
      }).get().map((role) => role.id);

      const permissionsRepo = useRepo(Permissions);
      const projectIdToCheck = projectId ? parseInt(projectId) : projectsStore.project?.id;

      return permissionsRepo
        .where('user_id', (state.user as User).id)
        .where((permission: Permissions) => {
          return (permission.project_id === projectIdToCheck &&
            rolesToCheck.includes(permission.role_id)) || (
              permission.role_id === 1 && includeAdmin
            );
        }).get().length > 0;
    },

    models: () => useRepo(User),

    hasAccessToRoute(): (routeName: string) => boolean {
      return (routeName: string) => {
        const navStore = useNavStore();
        const route = navStore.routesAsMap.get(routeName);

        if(route) {
          if(route.meta.roles) {
            return this.isRole(route.meta.roles);
          }

          return true;
        }

        return false;
      };
    },
  },

  actions: {
    async fetchLoggedInUser() {
      const [user, _error] = await AuthService.getLoggedInUser();

      this.userFetched.resolvePromise();

      if(user) {
        this.user = user;

        this.models.save(user);

        Bugsnag.addOnSession((session) => {
          session.setUser(user.id);
        });

        return user;
      }

      return undefined;
    },

    setUser(user: any) {
      this.user = user;

      this.models.save(user);

      Bugsnag.addOnSession((session) => {
        session.setUser(user.id);
      });
    },

    async signOut() {
      const [data, error] = await AuthService.logout();

      this.user = undefined;

      if(inCapacitor) {
        CapacitorService.delete(StoreKeys.AUTH_TOKEN);
      }

      if(data) {
        return data;
      } else {
        throw error;
      }
    },

    async signIn(email: string, password: string) {
      const [data, error] = await AuthService.login({ email, password });

      if(data) {
        const [user] = await AuthService.getLoggedInUser();

        this.setUser(user);

        return data;
      } else {
        throw error;
      }
    },

    async fetchUsers() {
      const [data, error] = await UserService.index();

      if(data) {
        this.models.save(data);

        return data;
      } else {
        throw error;
      }
    },

    async fetchUser(id: Id) {
      const [data, error] = await UserService.show(id);

      if(data) {
        this.models.save(data);

        return data;
      } else {
        throw error;
      }
    },

    async updateUser(id: Id, form: UserUpdateRequest) {
      const [data, error] = await UserService.update(id, form);

      if(data) {
        this.models.save(data);

        return data;
      } else {
        throw error;
      }
    },

    async createUser(form: UserCreateRequest) {
      const [data, error] = await UserService.create(form);

      if(data) {
        this.models.save(data);

        return data;
      } else {
        throw error;
      }
    },

    async deleteUser(id: Id) {
      const [data, error] = await UserService.delete(id);

      if(data) {
        this.models.destroy(id);

        return data;
      } else {
        throw error;
      }
    },

    async disableUser(id: Id, form: ApiRequests['api.v1.users.toggle-disable']) {
      const [data, error] = await UserService.disable(id, form);

      if(data) {
        this.models.save(data);

        return data;
      } else {
        throw error;
      }
    },

    async fetchAdminUsers(page = 1) {
      const [data, error] = await UserService.adminIndex(page);

      if(data) {
        this.models.flush();
        this.models.save(data.users.data);

        return data;
      } else {
        throw error;
      }
    },

    async fetchCompanyUsersIndex(id: Id) {
      const [data, error] = await UserService.company.index(id);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async attachCompanyUserRole(companyId: Id, projectId: Id, form: ApiRequests['api.v1.companies.users.attach']) {
      const [data, error] = await UserService.company.attachRole(companyId, projectId, form);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async detachCompanyUserRole(companyId: Id, projectId: Id, form: ApiRequests['api.v1.companies.users.detach']) {
      const [data, error] = await UserService.company.detachRole(companyId, projectId, form);

      if(data) {
        useRepo(Permissions).query().where('project_id', parseInt(projectId as string)).where('user_id', form.user_id)
          .delete();

        this.models.save(data);

        return data;
      }

      throw error;
    },
  },

  persist: {
    storage: localStorage,
    paths: ['user'],
  },
});
