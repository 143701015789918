import Role from '@/models/Role';
import RoleService from '@/services/RoleService';
import { defineStore } from 'pinia';
import { useRepo } from 'pinia-orm';

export const useRolesStore = defineStore('roles', {
  state: () => ({}),

  getters: {
    models: () => useRepo(Role),
  },

  actions: {
    async fetchRoles() {
      const [data, error] = await RoleService.index();

      if(data) {
        this.models.save(data);

        return data;
      } else {
        throw error;
      }
    },
  },
});
